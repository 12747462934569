/**
 * 
 * @package Navy
 * @author cho
 * @version 1.1
 * @link http://pagecho.com
 */
 /* latin */
 
 @font-face {
    font-family: 'icomoon';
    src:url('fonts/icomoon.eot?-i5ysuu');
    src:url('fonts/icomoon.eot?#iefix-i5ysuu') format('embedded-opentype'),
        url('fonts/icomoon.woff?-i5ysuu') format('woff'),
        url('fonts/icomoon.ttf?-i5ysuu') format('truetype'),
        url('fonts/icomoon.svg?-i5ysuu#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class^="icon-"], [class*=" icon-"] {
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-link:before {
    content: "\e005";
}
.icon-image:before {
    content: "\e010";
}
.icon-align-justify:before {
    content: "\e026";
}
.icon-layers:before {
    content: "\e031";
}
.icon-upload:before {
    content: "\e068";
}
.icon-location:before {
    content: "\e070";
}
.icon-head:before {
    content: "\e074";
}
.icon-menu:before {
    content: "\e120";
}
.icon-rss:before {
    content: "\f09e";
}
.icon-cross:before {
    content: "\e601";
}
.icon-arrow-down:before {
    content: "\e600";
}
.icon-cross {
    line-height: 21px!important;
} 
::selection { 
		text-shadow:none; 
		color:#666; 
		background:#FF9; 
}
::-moz-selection { 
		text-shadow:none; 
		color:#666; 
		background:#FF9; 
}
body {
    background-color:#FFF;
    color:#666;
    font-family: Helvetica,Tahoma,sans-serif,"Microsoft YaHei";
    font-size:14px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.move-block{
    position: relative;
}
a, button.submit {
    color:#999;
    text-decoration:none;
    -webkit-transition:all .1s ease-in;
    -moz-transition:all .1s ease-in;
    -o-transition:all .1s ease-in;
    transition:all .1s ease-in;
}
a:hover, a:active {
    color:#666;
}
mark {
    padding: 3px 3px 3px 5px;
    background: #fffdd1;
}
pre, code {
    background:#f8f8f8;
    font-family:Menlo, Monaco, Consolas, "Lucida Console", "Courier New", monospace;
    font-size:.92857em;
    display: block;
}
pre {
    margin: 2em 0;
    overflow:auto;
    background: none;
    border: 1px solid #666;
}
blockquote,.stressed {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 1.8em 0 1.8em -2.2em;
    padding: 0 0 0 1.6em;
    border-left: #888 0.2em solid;
    font-style: italic;
    color: #888;
}
input[type="text"], input[type="email"], input[type="url"], input[type="password"], textarea {
    padding:6px;
    border:1px solid #666;
    width:100%;
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    box-sizing:border-box;
    outline:none;
}
textarea {
    resize:vertical;
}
.clear {
    clear:both;
}
.clearfix, .col-group {
    zoom:1;
}
.clearfix:before, .col-group:before, .clearfix:after, .col-group:after {
    content:" ";
    display:table;
}
.clearfix:after, .col-group:after {
    clear:both;
}
.post-content a, .comment-content a {
    border-bottom:2px solid #ccc;
}
.post-meta a:hover, .post-content a:hover, .widget a:hover, .comment-content a {
    border-bottom-color:transparent;
	text-decoration: none;
}
#header {
    padding:60px 0 0;
    text-align:center;
}
/*.site-name a{
	background: url(./img/logo.png) 0 0 no-repeat;
	background-image: -webkit-image-set(url(./img/logo.png) 1x, url(./img/logo@2x.png) 2x);
	background-image: -moz-image-set(url(./img/logo.png) 1x, url(./img/logo@2x.png) 2x);
	background-image: -ms-image-set(url(./img/logo.png) 1x, url(./img/logo@2x.png) 2x);	
	background-image: -o-image-set(url(./img/logo.png) 1x, url(./img/logo@2x.png) 2x);
	width:159px;
	height:59px;
	display:center;
	padding-top: 20px;
	}*/
.site-name h1{
    padding: 0;
    margin:0;
    height: 0;
    overflow: hidden;
}
#logo {
    font: 50px/1.32 "Source Sans Pro", Segoe ui light, Trebuchet, Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, Microsoft JhengHei, 微軟正黑體, Microsoft YaHei, 微软雅黑, Helvetica, Arial, sans-serif;
    text-shadow: 1px 5px 5px #333;
    color:#333;
}
#logo span {
    color:#777;
}
#logo:hover {
    color:#777;
}
.description {
    margin:1em;
    font-family: diaryDesc,Helvetica,Tahoma,sans-serif,"Microsoft YaHei";
    font-size:18px;
    color:#666;
}
#nav-menu {
    padding:0;
    margin: 15px 0 10px;
}
#nav-menu a {
    text-decoration: none;
    padding: 4px 18px 3px;
    color: #fff;
    background-color: #666;
    border-radius: 4px;
    position: relative;
    z-index: 4;
    font-size: 14px;
    line-height: 1.5;
    display: inline-block;
}
#nav-menu a:first-child {
    border:none;
}
#nav-menu a:hover {

}
#nav-menu a.current {
    background-color: #222;
}
#nav-menu a.current:after {
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    border-top: 14px solid #222;
    bottom: -6px;
    content: "";
    display: block;
    position: absolute;
    z-index: 3;
    height: 0;
    text-align: center;
    left: 50%;
    margin-left: -14px;
    width: 0;
}
.site-search {
    display:none;
}
#search {
    position:relative;
    padding: 0 25px;
}
#search input {
    padding:6px 56px 6px 10px;
    line-height: 1.7;
    border:none;
    background-color: #666;
    color: #F8F8F8;
    font-size: 13px;
}
#search button {
    position:absolute;
    top:0;
    right:25px;
    border:none;
    padding:6px 15px;
    color:#666;
    font-size: 13px;
    line-height: 1.7;
    background-color: #222;
    color: #f8f8f8;
    width: 60px;
}
.col-8 {
    width:750px;
}
.col-12{width:100%;}
.container {
    width:1150px;
    padding:0 60px;
    margin:0 auto;
}
#secondary {
    height: 100%;
    width: 300px;
    top: 0;
    right: -330px;
    position: fixed;
    z-index: 100;
    box-shadow: -3px 0 8px rgba(0, 0, 0, 0.1);
    font-size: 13px;
    background-color: #222;
    color: #f8f8f8;
}
.sidebar{
    padding: 60px 0;
}
#main {
    padding-top:20px;
    margin: 0 auto;
}
.post {
    padding:20px 0 0;
}
.post-title {
    margin:0;
    color:#333;
    text-align:left;
    font-family: diaryH1,Helvetica,Tahoma,sans-serif,"Microsoft YaHei";
    font-size: 20px;
    font-weight: 600;
}
.post-title a {
    color:#999;
}
.post-title a:hover{
    color: #666;
}
.post-meta {
    padding:0;
    margin:0 0 10px;
    color:#999;
    text-align:left;
    display: block;
}
.post-content {
    font-size: 14px;
    line-height: 1.92;
    color:#666;
    padding-top:12px;
}
.post-content h3,.post-content h2{
    margin: 1.4em 0 1.1em;
}
.post-content pre code ,.comment-content pre code {
    padding: 1.5em 2em;
}
.post-content p code, .comment-content p code {
    display:inline;
    margin:0 5px;
    padding:3px 5px;
    border: 1px solid #666;
}
.post-content p {
    margin:0 0 1.234em;
}
.post-content img {
    padding:0.3em 0;
    /*width: 112.67%;
    margin-left: -6.33%;*/
}
.post-content ul {
    overflow: auto;
    padding: .5em 2.4em;
    border-radius: 3px;
    margin:1.8em 0;
}
.post-content ul li{
    text-indent: .5em;
}
.post-footer {
    position: relative;
    margin: 100px 0 0 0;
    padding: 48px 0 0 0;
    border-top: #ccc 1px solid;
    text-align: center;
}

.post-footer h4 {
    font-size: 1.8rem;
    margin: 0;
    padding-top: 5px;
    font-family: "Times New Roman", Georgia, Times, sans-serif;
}

.post-footer p {
    margin: 10px 0 0;
}
.author-ava{
    height: 80px;
    width: 80px;
    overflow: hidden;
    border-radius: 100%;
    border:0px solid #666;
    background-color: #fff;
    margin: -90px auto 20px;
}
.author-ava img{
    width: 100%!important;
    height: 100%!important;
}

.archive-title {
    padding-top:13px;
    color:#999;
    font-size:22px;
	display:none;
}
.more {
    padding:10px 0 0;
    text-align: center;
}
.more a {
    border:none;
}
.protected .text {
    width:50%;
}
.page-navigator {
    text-align: center;
    list-style:none;
    margin-top: 45px;
    padding:30px 0 0;
    font-size:16px;
    border-top: 1px solid #ccc;
}
.page-navigator li {
    display:inline-block;
    margin:0 5px 5px 0;
}
.page-navigator a {
    display:inline-block;
    padding:0 10px;
    height: 20px;
    line-height: 20px;
    padding: 7px 8px;
}
.page-navigator a:hover {
    background:#F8F8F8;
    border-bottom-color:#D26911;
}
.page-navigator .current a {
    background:#F8F8F8;
    border-bottom-color:#D26911;
}
.related-article{
    border:1px solid #666;
    border-left: none;
    border-right: none;
    margin-bottom: 5em;
}
.related-article div{
    width: 399px;
    float: left;
    padding: 20px 0;
}
.related-article div a{
    padding: 0 4em;
    font-size: 17px;
}
.nav-prev{
    text-align: left;
    border-right: 1px solid #666;
}
.nav-next{
    text-align: right;
}
.ds-powered-by {
	display:none
}
.comment-list, .comment-list ol {
    list-style:none;
    margin:0;
    padding:0;
}
.comment-list {
    margin-top:-1px;
}
.comment-list li {
    padding:22px 0 0;
    border-top:1px solid #666;
}
.comment-list li.comment-level-even {
    background:#FFF;
}
.comment-list li .comment-reply {
    float:right;
    margin-top:-39px;
    font-size:.92857em;
}
.comment-meta a {
    color:#999;
    font-size:.92857em;
}
.comment-author {
    display:block;
    margin-bottom:3px;
    color:#666;
}
.comment-author .avatar {
    float:left;
    margin:1px 10px 0 0;
    border: 1px solid #666;
    padding: 1px;
}
.comment-author cite {
    font-weight:bold;
    font-style:normal;
    line-height: 1.2;
}
.comment-awaiting-moderation {
    font-style:normal;
    float:right;
    margin-top:-20px;
    color:#777;
}
.comment-list .respond {
    margin:15px 0;
    border-top:1px solid #666;
}
.comment-body .respond {
    border:none;
    margin:0 0 25px;
}
.respond .cancel-comment-reply {
    float:right;
    margin-top:15px;
    font-size:.92857em;
}
#comment-form {
    padding-top:5px;
}
#comment-form label {
    display:block;
    color:#888;
    position:absolute;
    margin:7px;
}
#comment-form input {
    padding:8px 6px;
    height:36px;
    border:solid 1px #D4D4D4;
    background:#fdfdfd;
    padding-left:45px;
    color: #333;
}
button.submit {
    border:1px solid #272727;
    display:block;
    line-height:32px;
    width:100%;
    outline:none;
    color: #fff;
    background:#272727;
    height: 38px;
}
button.submit:hover {
    background:#505050;
    border-color: #505050;
}
textarea.textarea {
    line-height:1.8;
    padding:5px 10px 5px 10px;
    width:100%;
    height:174px;
    padding:10px;
    overflow:auto;
    margin:0;
    line-height:1.8;
    color:#222;
    font-size:14px;
    background:#fdfdfd!important;
    color: #333;
}
.col2 p, .col1 p {
    margin:9px 0;
}
.col1 p {
    margin-right:220px;
}
.col1 {
    margin-right:-220px;
    height:auto;
    float:left;
    width:100%;
    position:relative;
    background-position:0 0;
}
.col2 {
    width:204px;
    float:right;
    position:relative;
    overflow:hidden;
    background-position:right top;
}
.widget-title{
    font-size: 15px;
    margin: 15px 26px 0;
    padding-bottom: 10px;
    font-weight: normal;
}
#comments .widget-title {
    color:#999;
    line-height:2.7;
    margin-top:0;
    font-size:16px;
    border-bottom:1px solid #666;
    display:block;
    margin: 0;
    padding-bottom: 0px;
}
.widget{
    margin-bottom: 30px;
}
.widget ul{
    margin: 0;
    padding: 0;
    list-style: none;
}
.widget ul li{
    line-height: 1.72;
}

.widget-list {
    list-style:none;
    padding:0;
    margin:50px 0 0 -1px;
}
.widget-list2{
    margin:0;
}
.widget-list li {
    margin:0;
    line-height:1.5;
}
.widget-list li span{
    text-transform: uppercase;
    color: #ccc;
}
.date-month{
    font-size: 12px;
    margin-right: 10px;
    display: inline-block;
    width: 3em;
}
.widget-list h3{
    margin: 0;
    font-size: 13px;
}
.widget-list h3 a{
    display: block;
    margin: 0;
    line-height: 1.7;
    padding: .4em 25px .4em 26px;
    font-weight: normal;
    color: #ccc;
}
.widget-list h3 a.active,.widget-list h3 a.active:hover,.widget-list h3 a:hover{
    background-color: #666;
    color: #fff;
}
.widget-list a:hover span{
    color:#fff;
}
.widget-list h3 a:hover{
    color: #fff;
    background-color: #666;
}

#response {
    margin-bottom:1em;
}
#footer {
    padding:.8em 0 3.6em;
    line-height:1.5;
    color:#666;
    text-align: center;
}
.error-page {
    margin-top:100px;
    margin-bottom:100px;
}
.comment-content {
    line-height:1.5;
    word-wrap:break-word;
    padding:10px 40px 10px 0;
}
.post-content h2, .comment-content h2 {
    font-size:1.28571em;
}
.comment-content img {
    max-width:100%;
}
.post-content a img {
    background:#FFF;
    position:relative;
    bottom:-4px;
}
.post-content hr, .comment-content hr {
    margin:2.4em auto;
    border:1px solid #666;
    border-width:1px 0 0 0;
    position: relative;
}
.aligncenter, div.aligncenter {
    display:block;
    margin-left:auto;
    margin-right:auto;
}
.alignleft {
    float:left;
}
.alignright {
    float:right;
}
img.alignleft {
    margin:5px 15px 0 0;
}
img.alignright {
    margin:5px 0 0 15px;
}
/* archives page*/
 .al_year {
    width:60px;
    padding: 10px 0;
}
.al_mon_list {
    margin-left: 90px;
    margin-top: -2.2em;
    border-left: 1px solid #666;
}
.al_mon_list li {
    list-style: none;
    line-height: 2;
}
.al_mon_list li a {
    margin-left: 1.5em;
}
.post-content-pages {
    font-size: 14px;
}
.about-contact,.about-contact li{
    list-style: none;
    margin: 0;
    padding: 0;
}
.about-contact{
    margin: 18px 0 10px;
}
.about-contact li{
    font-size: 18px;
    display: inline;
    margin: 0 2px;
}
.about-contact li a{
    color: #000;
}
.body404{
    position: absolute;
    height: 100%;
    width: 100%;
    background:#fff url(./img/about.png)no-repeat right top;
background-size: auto 100%;
    text-shadow:1px 1px 0 #fff;
}
.body-about .body404{
    background:#fff;
}
.site-name404 {
    margin: 0 auto;
    width: 3em;
    text-align: center;
    letter-spacing: 2px;
    font:700 88px/1.2 "ff-tisa-web-pro", Cambria, "Times New Roman", Georgia, Times, sans-serif;
}
.site-name404 h1{
    margin: 0 0 10px;
    font:700 58px/1.2 "ff-tisa-web-pro", Cambria, "Times New Roman", Georgia, Times, sans-serif;
}
.title404 span{
    font-size: 15px;
    width: 2px;
}
.site-name404 i {
    font-style: normal;
}
.title404 p{
    font-size: 20px;
    margin: 0.5em 0 .6em;
}
.info404{
    position: absolute;
    top: 50%;
    text-align: center;
    width: 100%;
    margin-top: -160px;
}
.body-about .info404{
    margin-top: -180px;
}
#footer404{
    margin-top:30px;
}
.index404{
    margin-top: 13px;
    display: inline-block;
    padding: 14px 27px 14px 29px;
    color: #fff;
    white-space: nowrap;
    border-radius: 50px;
    text-align: center;
    cursor: pointer;
    background: #666;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 1px;
    font-size: 14px;
    -moz-user-select: -moz-none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    text-shadow:none;
}
.index404:hover{
    background-color: #000;
    color: #fff;
}
.icon-about{
    padding: 10px 0 25px;
}
.icon-about a{
    font-size: 20px;
    margin: 5px;
    color: #fff;
    background-color: #000;
    border-radius: 100%;
    padding: 6px;
}
.link-back2top {
    bottom: 50px;
    height: 32px;
    position: fixed;
    width: 32px;
    z-index: 3;right:50%;margin-right:-480px
}
.link-back2top a {
    background: url(./img/bg_top.png) no-repeat scroll 13px center #D6D6D6;
    border-radius: 32px 32px 32px 32px;
    display: block;
    font-size: 0;
    height: 32px;
    line-height: 0;
    overflow: hidden;
    width: 32px;
}
.link-back2top a:hover {
    background-color: #999;
}
 @media print, screen and (max-width:1290px) {
    .container {
        width:auto;
    }
}
@media print, screen and (max-width:1057px) {
    .container {
        width:auto;
    }
    .post-content {
        font-size: 16px;
    }
}
@media print, screen and (max-width:860px) {
    .post-content {
        font-size: 15px;
    }
    #header {
        text-align:center;
    }
    #main {
        width:100%;
        margin-right:0;
    }
    #nav-menu {
        margin:35px 0 -1px;
    }
    .res-cons {
        width:auto;
        margin-right:0;
    }
    .post-content img {
        max-width:100%;
        margin-left: 0;
    }
    blockquote{
        margin-left: 0;
    }
}
@media print, screen and (max-width:680px) {
    .container {
        padding:0 20px;
    }
    .post-title {
        font-size:22px;
    }
    .page-navigator {
        padding:15px 0 0;
    }
    #footer {
        padding-top:10px;
    }
    .page-navigator a {
        padding:0 6px;
    }
    .post-content img {
        padding:0.2em 0;
    }
    .post {
        padding:27px 0 0;
    }
    #nav-menu a {
        padding:0 15px;
        line-height:27px;
        height:27px;
        font-size:13px;
    }
}
@media print, screen and (max-width:500px) {
    .col2 {
        width:140px;
    }
    .post-meta li.comment-count {
        display: none;
    }
    .col1 p {
        margin-right:160px;
    }
    .col1 {
        margin-right:-160px;
    }
    .post-content-pages {
        font-size: 14px;
    }
    .al_mon_list {
        margin-left: 70px;
        padding-left: 20px;
    }
}
.about{
    width: 100%;
}
.side-click{
    position: fixed;
    right: 0;
    top: 0;
    z-index: 999;
    margin: 20px 20px 0 24px;
    color: #000;
    border: 3px solid #000;
    width: 20px;
    height: 20px;
    line-height: 22px;
    font-size: 20px;
    border-radius: 100px;
    background: #fff;
    transition-property: all;
    transition-duration: .3s;
    -webkit-transition-timing-function: cubic-bezier(0.165,0.630,0.140,0.820);
    -moz-transition-timing-function: cubic-bezier(0.165,0.630,0.140,0.820);
    -ms-transition-timing-function: cubic-bezier(0.165,0.630,0.140,0.820);
    -o-transition-timing-function: cubic-bezier(0.165,0.630,0.140,0.820);
    transition-timing-function: cubic-bezier(0.165,0.630,0.140,0.820);
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    cursor:pointer;
}